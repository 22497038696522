<template>
  <div class="sidebar-menu fixed mw-100 sidebar-menu fixed mw-100 bg-cl-white">
    <div class="row brdr-bottom-1 brdr-cl-bg-matterhorn m0">
      <div
        v-if="submenu.depth"
        class="col-xs-12 bg-cl-light-white p0"
      >
        <sub-btn type="back" :name="getPrevName ? getPrevName : $t('Back')" class="bg-cl-transparent brdr-none" />
      </div>
      <div v-if="!submenu.depth" class="col-xs-12 bg-cl-light-white py10 px25 flex middle-xs">
        <span class="uppercase cl-dark-blue h5 weight-600">{{ $t('menu') }} </span>
        <i class="cl-black close-icon-sidebar icon-close ml-auto" @click="closeMenu" />
      </div>
    </div>
    <div class="sidebar-menu__container row" ref="container">
      <div class="col-xs-12 h5">
        <router-link :to="localizedRoute('/selling')">
          <button @click="closeMenu" class="my10 header-sell-btn">
            {{ $t('Sell your products') }}
          </button>
        </router-link>
        <ul class="p0 m0 relative sidebar-menu__list" :style="mainListStyles">
          <li
            class=" bg-cl-primary cl-black flex"
            :key="category.slug"
            @click="closeMenu"
            v-for="category in visibleCategories"
          >
            <div
              v-if="isCurrentMenuShowed && (category.id !== categoryIdsMap.fashionSubscriptionCategoryId)"
              class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
            >
              <sub-btn
                v-if="category.children_count > 0"
                class="bg-cl-transparent brdr-none h5"
                :id="category.id"
                :name="category.name"
              />
              <router-link
                v-else
                class="px25 py5 cl-dark-blue no-underline col-xs weight-600 uppercase"
                :to="categoryLink(category)"
              >
                {{ category.name }} <!-- Direct link to category -->
              </router-link>
            </div>
            <!-- subcategory -->
            <sub-category
              :category-links="category.children_data"
              :id="category.id"
              :parent-slug="category.slug"
              :parent-path="category.url_path"
            />
            <div
              v-if="category.id === categoryIdsMap.fashionSubscriptionCategoryId"
              class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
            >
              <sub-btn
                :name="$t('Subscription')"
                id="o-nas-links"
                class="bg-cl-transparent brdr-none h5"
              />
              <sub-category-useful-links
                :useful-links="usefulLinks"
                id="o-nas-links"
                @click.native="closeMenu"
              />
            </div>
          </li>
          <!-- Categories END -->
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py10 cl-dark-blue brdr-bottom-1 brdr-cl-matterhorn cl-black no-underline h5 uppercase weight-600 lh25"
              :to="localizedRoute('/i/' + $t('how-it-works-sell'))"
              exact
            >
              {{ $t("How it works") }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py10 cl-dark-blue brdr-bottom-1 brdr-cl-matterhorn cl-black no-underline h5 uppercase weight-600 lh25"
              :to="localizedRoute('/i/' + $t('showroom'))"
              exact
            >
              E-garderobe
            </router-link>
          </li>
          <li
            @click="login"
            class="bg-cl-primary cl-black"
          >
            <sub-btn
              v-if="currentUser"
              id="my-account-links"
              :is-category="false"
              :name="$t('My account')"
              class="bg-cl-transparent brdr-none h5 brdr-bottom-1 brdr-cl-matterhorn"
            />
            <sub-category
              v-if="currentUser"
              :my-account-links="myAccountLinks"
              id="my-account-links"
              @click.native="closeMenu"
            />
            <a
              v-if="!currentUser && isCurrentMenuShowed"
              href="#"
              @click.prevent="closeMenu"
              class="block w-100 px25 py10 cl-dark-blue no-underline h5 brdr-bottom-1 brdr-cl-matterhorn weight-600 uppercase lh25"
            >
              {{ $t('My account') }}
            </a>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary save-area-overlay"
          >
            <div class="pointer pl25 py10 flex" @click="showLanguagesModal()">
              <span class="h5 language-modal-label">{{ $t('Shop in') }}: {{ $t($store.state.storeView.tax.defaultCountry) }} ({{ $store.state.storeView.i18n.currencySign }})</span>
              <img class="language-modal" :src="`/assets/country/${selectDefaultCountry}.png`" alt="country flag" width="30px" height="30px">
            </div>
            <div class="px25">
              <p class="cl-dark-blue weight-600 uppercase m0 pb5">
                {{ $t('join us') }}
              </p>
              <a class="no-underline" rel="noreferrer nofollow" href="https://www.facebook.com/egarderobe" target="_blank"><i class="icon-FB pr10 cl-black" /></a>
              <a class="no-underline" rel="noreferrer nofollow" href="https://www.instagram.com/egarderobe/" target="_blank"><i class="icon-Instagram pr10 cl-black" /></a>
              <a class="no-underline" rel="noreferrer nofollow" href="https://www.youtube.com/channel/UChcWZZk4V8U-0H5xLHFckhQ" target="_blank"><i class="icon-Youtube instagram pr10 cl-black" /></a>
              <a class="no-underline" rel="noreferrer nofollow" href="https://pl.pinterest.com/egarderobe/_created/" target="_blank"><i class="icon-Pinterest pinterest pr10 cl-black" /></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import { mapState, mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'
import SidebarMenu from '@vue-storefront/core/compatibility/components/blocks/SidebarMenu/SidebarMenu'
import SubBtn from 'theme/components/core/blocks/SidebarMenu/SubBtn'
import SubCategory from 'theme/components/core/blocks/SidebarMenu/SubCategory'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import groupBy from 'lodash-es/groupBy'
import SubCategoryUsefulLinks from 'theme/components/core/blocks/SidebarMenu/SubCategoryUsefulLinks'

export default {
  components: {
    SubCategory,
    SubCategoryUsefulLinks,
    SubBtn
  },
  mixins: [SidebarMenu],
  data () {
    return {
      usefulLinks: [
        { title: i18n.t('PURCHASE A SUBSCRIPTION'), link: '/' + i18n.t('abonament-modowy-wybierz-pakiet') },
        { id: 3, title: i18n.t('AVAILABLE FOR SUBSCRIPTION'), link: '?subscription=true' }
      ],
      myAccountLinks: [
        {
          id: 1,
          name: i18n.t('My profile'),
          url: '/my-account'
        },
        {
          id: 2,
          name: this.$t('My orders'),
          url: '/my-account/orders'
        },
        {
          id: 3,
          name: this.$t('Sell'),
          url: '/my-account/my-selling-products'
        },
        {
          id: 4,
          name: this.$t('My addresses'),
          url: '/my-account/shipping-details'
        },
        {
          id: 5,
          name: i18n.t('My subscriptions'),
          url: '/my-account/subscriptions'
        },
        {
          id: 6,
          name: this.$t('My active subscriptions'),
          url: '/my-account/active-subscriptions'
        },
        {
          id: 7,
          name: this.$t('My subscription cards'),
          url: '/my-account/cards'
        },
        {
          id: 8,
          name: this.$t('My Recently viewed products'),
          url: '/my-account/recently-viewed'
        }
      ],
      componentLoaded: false
    }
  },
  computed: {
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user.current
    }),
    ...mapGetters('category-next', ['getMenuCategories']),
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    categoryIdsMap () {
      return config.server.categoryIdsMap
    },
    selectDefaultCountry () {
      return this.$store.state.ui.selectedCountry
        ? this.$store.state.ui.selectedCountry
        : 'PL'
    },
    visibleCategories () {
      return this.categoriesMap[config.server.categoryIdsMap.rootCategoryId]
    },
    sortedCategoriesMap () {
      let arr = this.visibleCategories
      return arr.sort(this.cmpNames)
    },
    // visibleCategories () {
    //   return this.categories.filter(category => {
    //     return category.product_count > 0 || category.children_count > 0
    //   })
    // },
    getPrevName () {
      // KULTOWY SPECIFIC: only one layer of menu depth
      const prevName = this.visibleCategories.find(cat => {
        return cat.id === this.submenu.path[this.submenu.path.length - 1]
      })
      return prevName && prevName.name
    },
    isCurrentMenuShowed () {
      return !this.submenu || !this.submenu.depth
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
      let body = document.getElementsByTagName('body')[0]
      body.classList.add('iphone-scroll')
    })
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('iphone-scroll')
  },
  methods: {
    showLanguagesModal () {
      this.$bus.$emit('modal-show', 'modal-switcher')
    },
    cmpNames (a, b) {
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    },
    login () {
      if (!this.currentUser && this.isCurrentMenuShowed) {
        this.$nextTick(() => {
          this.$store.commit('ui/setAuthElem', 'login')
          this.$bus.$emit('modal-show', 'modal-signup')
          this.$router.push({ name: 'my-account' })
        })
      }
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$bg-secondary: color(secondary, $colors-background);
$color-silver: color(silver);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);
$color-main: color(main);

// special case for iphones
.save-area-overlay {
  margin-bottom: 200px;
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
    margin-bottom: 260px;
  }
}

.sidebar-menu {
  height: 100vh;
  width: 350px;
  // overflow-y: scroll;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100vh;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
  }

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 55px);
  }

  &__list {
    transition: transform $duration-main $motion-main;
  }

  ul {
    list-style-type: none;
  }

  li {
    &:hover,
    &:focus {
      background-color: $color-silver;
    }
    &.bg-cl-primary {
      &:hover,
      &:focus {
        background-color: $bg-secondary;
      }
    }
  }

  .subcategory-item {
    display: flex;
    width: 100%;
  }
}
.no-underline {
  text-decoration: none;
}
.language-modal-label {
  color: #141E39;
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.header-sell-btn {
  height: 38px;
  padding-left: 32px;
  padding-right: 32px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 20px 20px;
  text-transform: uppercase;
  letter-spacing: 1.23px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
}
</style>
