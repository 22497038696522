<template>
  <button
    v-if="type === 'next'"
    class="inline-flex h5 between-xs align-left px0 uppercase w-100 flex middle-xs cl-dark-blue weight-600 px25 "
    type="button"
    @click.stop="next()"
    :aria-label="$t('Show subcategories')"
    data-testid="categoryButton"
  >
    {{ name }}
    <i class="icon-chevron-right" />
  </button>
  <button
    v-else
    class="cl-dark-blue h5 inline-flex align-left px0 uppercase flex middle-xs weight-600 py10 px25"
    type="button"
    @click.stop="back()"
    :aria-label="$t('Back')"
  >
    <i class="icon-chevron-left pr20" />
    {{ name }}
  </button>
</template>
<script>
import { mapState } from 'vuex'
import config from 'config'
export default {
  name: 'SubBtn',
  props: {
    id: {
      type: null,
      default: ''
    },
    type: {
      type: String,
      default: 'next'
    },
    name: {
      type: String,
      default: ''
    },
    isCategory: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      submenu: state => state.ui.submenu
    })
  },
  methods: {
    next () {
      if (config.entities.category.categoriesDynamicPrefetch && this.isCategory) this.$store.dispatch('category-next/fetchMenuCategories', { parent: this.id })
      this.$store.commit('ui/setSubmenu', {
        id: this.id,
        depth: ++this.submenu.depth
      })
    },
    back () {
      this.$store.commit('ui/setSubmenu', {
        depth: --this.submenu.depth
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gray: color(silver);
$color-black: color(matterhorn);
$color-main: color(main);
button {
  // line-height: 24px;
  // i {
  // line-height: 24px;
  // }
  i.left {
    transform: rotate(270deg);
  }
  i.right {
    transform: rotate(90deg);
  }
  &:hover,
  &:focus {
    i {
      color: $color-black;
    }
  }
}
.back-btn {
  vertical-align: middle;
  line-height: 26px;
  color: $color-main;
}
</style>
