<template>
  <div>
    <ul
      v-if="isCurrentMenuShowed"
      class="sidebar-submenu absolute bg-cl-white w-100 p0"
      :style="styles"
    >
      <li
        class="flex save-area-overlay"
        :key="link.link"
        v-for="(link, index) in usefulLinks"
      >
        <div
          class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
        >
          <router-link
            class="px25 lh24 py5 cl-dark-blue no-underline col-xs bg-cl-primary uppercase h5" v-show="index !== 3"
            :to="index === 2 ? localizedRoute(getMainCategory.url_path + link.link) : localizedRoute(link.link)"
          >
            {{ link.title }}
          </router-link>
        </div>
      </li>
      <a rel="noreferrer nofollow" href="https://faq.e-garderobe.com" class="px25 lh24 py5 cl-dark-blue no-underline col-xs uppercase h5" target="_blank">Faq</a>
    </ul>
  </div>
</template>
<script>
import config from 'config'
import { mapState, mapGetters } from 'vuex'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'SubCategory',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    usefulLinks: {
      type: null,
      default: false
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    ...mapState({
      submenu: state => state.ui.submenu,
      path: state => state.ui.submenu.path
    }),
    getSubmenu () {
      return this.submenu
    },
    getMainCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.mainCategoryId)
    },
    styles () {
      const pos = this.submenu.path.indexOf(this.id)
      return pos !== -1 ? {
        zIndex: pos + 1
      } : false
    },
    isCurrentMenuShowed () {
      return this.getSubmenu && this.getSubmenu.depth && this.getSubmenu.path[this.getSubmenu.depth - 1] === this.id
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>

<style lang="scss" scoped>
// special case for iphones
.save-area-overlay {
  &:last-child {
    margin-bottom: 80px;
    @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
      margin-bottom: 200px;
    }
  }
}
  .sidebar-submenu {
    left: 0;
    top: 0;
    min-height: 100%;
    transform: translateX(-100%);
  }

  .subcategory-item {
    display: flex;
    width: 100%;
  }
  .lh24 {
    line-height: 24px;
  }
</style>
